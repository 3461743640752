import React, {useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate,
    Outlet,
    useNavigate,
    useLocation
} from "react-router-dom";
import {
    AppstoreOutlined,
    BarChartOutlined,
    CloudOutlined, DownOutlined, MoreOutlined,
    ShopOutlined,
    TeamOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {Col, Dropdown, Layout, Menu, notification, Row, Skeleton, theme} from 'antd';
import {Space, Typography} from 'antd';
import Onboarding from "./Onboarding";
import {ENV_API_HOST} from "./config";
import TableView from "./view/TableView";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//<FontAwesomeIcon icon={faEllipsis} />
import {faPlus, faEllipsis, faChevronDown, faRightFromBracket, faShuffle} from "@fortawesome/free-solid-svg-icons";
import {createTableRequest, useApi} from "./utils/requests";
import SettingsView from "./view/SettingsView";
import {WorkspaceTableMenu, WorkspaceInterfaceMenu} from "./utils/WorkspaceTableMenu";
import SignupView from "./view/SignupView";
import SigninView from "./view/SigninView";
import Avatar from "react-avatar";
import ForgotPasswordView from "./view/ForgotPasswordView";
import ConfirmForgotPasswordView from "./view/ConfirmForgotPasswordView";
import WorkspaceDropdown from "./features/WorkplaceDropdown";
import PluginView from "./view/PluginView";
import InterfaceView from "./view/InterfaceView";
import InterfacePublicView from "./view/InterfacePublicView";
import WorkflowView from "./view/WorkflowView";
import {useTheme} from "./utils/ThemeProvider";
import UserProfileView from "./view/UserProfileView";
import {useUser} from "./utils/UserProvider";
import CompanySettingsView from "./view/CompanySettingsView";
import TemplateView from "./view/TemplateView";
import InterfaceHistoryView from "./view/InterfaceHistoryView";

const {Text} = Typography;
const {Header, Content, Footer, Sider} = Layout;

const items = [
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    BarChartOutlined,
    CloudOutlined,
    AppstoreOutlined,
    TeamOutlined,
    ShopOutlined,
].map((icon, index) => ({
    key: String(index + 1),
    icon: React.createElement(icon),
    label: `nav ${index + 1}`,
}));


function ProfileBottomLink(props) {
    const {user} = props;


    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        window.location.href = "/signin";
    }

    const items = [
        {
            key: 'userProfile',
            label: (
                <Link to={"/user/profile"}>
                    Your profile
                </Link>
            ),
        },
        {
            key: 'companyProfile',
            label: (
                <Link to={"/company/settings"}>
                    Company settings
                </Link>
            ),
        },
        {
            key: 'logout',
            label: (
                <Link onClick={handleLogout}>
                    Logout
                </Link>
            ),
        },
    ];

    return (
        <Dropdown menu={{items}} placement="top">
            <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col style={{padding: "0px 7px 0px 5px"}}>
                    <Avatar round={true} name={user.email} size="32"/>
                </Col>
                <Col span={15}
                     style={{color: "#fff", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>

                    <Link style={{color: "#fff"}}>{user.email}</Link>
                    {/*{user.email}*/}
                </Col>
                {/*<Col span={1} style={{color: "#fff", paddingLeft: "10px"}}>*/}
                {/*    <Link onClick={handleLogout}><FontAwesomeIcon icon={faRightFromBracket}/></Link>*/}
                {/*</Col>*/}
            </Row>
        </Dropdown>
    )
}


function BottomMenu(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {user} = props;


    let selectedKeys = [];

    if (location.pathname.startsWith('/settings')) {
        selectedKeys.push('settings');
    }

    if (location.pathname.startsWith('/plugins')) {
        selectedKeys.push('plugins');
    }



    if (location.pathname.startsWith('/template')) {
        selectedKeys.push('template');
    }


    return (<div>
        <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
            <Menu.Item key='template'>
                <Link to={"/template"}>Templates</Link>
            </Menu.Item>

            <Menu.Item key='plugins'>
                <Link to={"/plugins"}>Plugins</Link>
            </Menu.Item>
        </Menu>

        <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
            <Menu.Item key='settings'>
                <Link to={"/settings"}>Settings</Link>
            </Menu.Item>
        </Menu>

        <div style={{borderTop: "1px solid #A6ADB4", paddingTop: "10px"}}>

            <ProfileBottomLink user={user}/>

        </div>
    </div>)
}

function ProtectedLayout(props) {
    const location = useLocation();
    const {createTableRequest, getAuthMeRequest, createInterfaceRequest} = useApi();
    const navigate = useNavigate();
    const {isDarkMode, direction, toggleTheme, toggleDirection} = useTheme();
    const {updateUser} = useUser();

    const {token: {colorBgContainer, borderRadiusLG}} = theme.useToken();
    const [tableList, setTableList] = useState(null);

    const [isTableCreating, setIsTableCreating] = useState(false);
    const [isInterfaceCreating, setIsInterfaceCreating] = useState(false);


    const [newTableId, setNewTableId] = useState(null);
    const [newInterfaceId, setNewInterfaceId] = useState(null);


    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [userData, setUserData] = useState({});

    // The layout for authenticated users. Put your Sidebar and any other
    // components that should be visible only in protected routes here.

    const checkAuth = async () => {
        setIsAuthLoading(true);
        const {status, dataResponse} = await getAuthMeRequest();
        console.log("checkAuth = ", status, dataResponse);

        if (status == 401) {
            navigate("/signin");
        }

        setUserData(dataResponse);
        updateUser(dataResponse);
        setIsAuthLoading(false);
    }

    useEffect(() => {
        console.log("[ProtectedLayout] useEffect = ", props);
        checkAuth();
    }, [])

    const handleCreateNewTable = async () => {
        setIsTableCreating(true);
        const response = await createTableRequest();
        const isOk = response[0];
        if (isOk) {
            const newTableId = response[1];
            setNewTableId(newTableId);
        }
        setIsTableCreating(false);
    }

    const handleCreateNewInterface = async () => {
        setIsInterfaceCreating(true);
        const {responseStatus, responseData} = await createInterfaceRequest();
        const isOk = responseStatus;
        if (isOk) {
            const newId = responseData;
            setNewInterfaceId(newId);
            console.log("interface id = ", newId);
            // setNewTableId(newId);
        }
        setIsInterfaceCreating(false);
    }

    const workspaceDropdownList = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    1st menu item
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    2nd menu item
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    3rd menu item
                </a>
            ),
        },
    ];


    if (isAuthLoading) {
        return (<Layout style={{overflow: 'auto', height: '100vh'}}>
            <Row>
                <Col span={2} offset={1}>
                    <Skeleton active/>
                    <Skeleton active/>
                    <Skeleton active/>
                </Col>
                <Col span={20} offset={1}>
                    <Skeleton active/>
                    <Skeleton active/>
                    <Skeleton active/>
                </Col>

            </Row>

        </Layout>)

    }

    console.log("[protected layer called]")

    const onWorkspaceSelectUpdate = (workspaceId) => {
        console.log("onWorkspaceSelectUpdate: ", workspaceId);
        navigate(`/workspaces/${workspaceId}/`);
    }

    let selectedKeys = [];
    if (location.pathname.startsWith('/workflow')) {
        selectedKeys.push('workflow');
    }

    return (
        <Layout hasSider>
            <Sider
                style={{overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0}}
            >


                <div
                    style={{position: "relative", height: '100vh', marginBottom: "250px"}}
                >
                    <div>

                        <div style={{flex: '0 0 auto'}}>
                            <WorkspaceDropdown onSelectUpdate={onWorkspaceSelectUpdate}/>

                            <div>
                                <Menu theme="dark" mode="inline" selectedKeys={selectedKeys}>
                                    <Menu.Item key='workflow'>
                                        <Link to={"/workflow"}>Workflows</Link>
                                    </Menu.Item>

                                </Menu>
                            </div>
                        </div>


                        <div

                        >
                            <div style={{padding: "10px 5px"}}>
                                <Row style={{"color": "#A7ADB3"}}>
                                    <Col span={21}>
                                        <span>Records</span>
                                    </Col>
                                    <Col span={2} style={{textAlign: "right"}}>
                                <span><Link onClick={handleCreateNewTable}><FontAwesomeIcon
                                    icon={faPlus}/></Link></span>
                                    </Col>
                                </Row>
                            </div>

                            <WorkspaceTableMenu newTableId={newTableId} isCreatingTable={isTableCreating}/>

                            <div style={{padding: "10px 5px"}}>
                                <Row style={{"color": "#A7ADB3"}}>
                                    <Col span={21}>
                                        <span>Interfaces</span>
                                    </Col>
                                    <Col span={2} style={{textAlign: "right"}}>
                                    <span><Link onClick={handleCreateNewInterface}><FontAwesomeIcon
                                        icon={faPlus}/></Link></span>
                                    </Col>
                                </Row>
                            </div>
                            <WorkspaceInterfaceMenu newId={newInterfaceId} isInterfaceCreation={isInterfaceCreating}/>

                            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                            <br/>

                        </div>


                    </div>

                    <div
                        //     style={{
                        //     position: "absolute",
                        //     bottom: 0,
                        //     left: 0,
                        //     right: 0,
                        //     backgroundColor: '#001529',
                        //     borderTop: "1px solid #A6ADB4",
                        //     padding: "10px"
                        // }}
                        //
                        // className={"ant-layout-sider-trigger"}

                        style={{
                            borderTop: "1px solid #A6ADB4",
                            width: "200px",
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                            height: "188px",
                            color: "#fff",
                            // lineHeight: "148px",
                            background: "#001529",
                        }}>


                        <div
                            // style={{position: "absolute", bottom: '10px', left: 0, right: 0}}
                        >
                            <BottomMenu user={userData}/>
                        </div>

                    </div>


                </div>


            </Sider>
            <Layout style={{marginLeft: 200}}>
                <Outlet/>
            </Layout>
        </Layout>
    );
}


function App() {
    // const [api, contextHolder] = notification.useNotification();


    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/signup" exact element={<SignupView/>}/>
                    <Route path="/signin" exact element={<SigninView/>}/>
                    <Route path="/forgot-password" exact element={<ForgotPasswordView/>}/>
                    <Route path="/forgot-password/confirm" exact element={<ConfirmForgotPasswordView/>}/>
                    <Route element={<ProtectedLayout/>}>
                        <Route path="/" exact element={<Onboarding/>}/>
                        {/*<Route path="/product" exact element={<ProductList accountData={accountData} />}/>*/}
                        <Route path="/workflow/" exact element={<WorkflowView/>}/>
                        <Route path="/template/" exact element={<TemplateView/>}/>

                        {/*TemplateView*/}
                        <Route path="/workspace/:workspaceId/" exact element={<Onboarding/>}/>
                        <Route path="/table/:tableId/" exact element={<TableView/>}/>
                        <Route path="/interface/:interfaceId/" exact element={<InterfaceView/>}/>
                        <Route path="/interface/:interfaceId/history" exact element={<InterfaceHistoryView/>}/>
                        <Route path="/i/:interfaceSlug/" exact element={<InterfacePublicView/>}/>


                        <Route path="/plugins/" exact element={<PluginView/>}/>
                        <Route path="/settings/" exact element={<SettingsView/>}/>
                        <Route path="/user/profile/" exact element={<UserProfileView/>}/>
                        <Route path="/company/settings/" exact element={<CompanySettingsView />}/>
                    </Route>
                    {/* Other Routes you want to protect */}

                </Routes>
            </Router>
        </div>
    );
};

export default App;
