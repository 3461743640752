import {ENV_ACCOUNT_API_HOST, ENV_API_HOST} from "../config";
import {useNotification} from "./NotificationContext";

const getToken = () => {
    return localStorage.getItem('jwtToken');
};


const useApi = () => {
    const notification = useNotification();

    const createTableRequest = async () => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/table`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return [false, null]
        }

        return [true, dataResponse["table_id"]];
    }


    const createInterfaceRequest = async () => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            }
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse["id"];
        return {responseStatus, responseData};
    };


    const putInterfaceSourceTableRequest = async (interfaceId, tableId) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/table/source`;
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({
                table_id: tableId
            })
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse;
        return {responseStatus, responseData};
    };


    const putInterfaceVersionFieldsChangesRequest = async (interfaceId, settingsFormData) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/version/fields`;
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({
                data: settingsFormData
            })
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse;
        return {responseStatus, responseData};
    };


    const putInterfaceVersionChangesSettingsRequest = async (interfaceId, settingsFormData) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/version/settings`;
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({
                data: settingsFormData
            })
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse;
        return {responseStatus, responseData};
    };


    // /interface/<account_interface_id>/version/publish


    const postInterfaceVersionPublishRequest = async (interfaceId) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/version/publish`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            }
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse;
        return {responseStatus, responseData};
    };


    const postInterfaceVersionCreateNewRowRequest = async (interfaceVersionId) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/interface_version/${interfaceVersionId}/row`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            }
        });
        const dataResponse = await response.json();
        const responseStatus = response.ok;

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });

            const responseData = null;
            return {responseStatus, responseData}
        }

        const responseData = dataResponse;
        return {responseStatus, responseData};
    };


    const postColumnForTableRequest = async (tableId, formData) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/table/${tableId}/column`;
        try {
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${jwtToken}`,
                },
                body: JSON.stringify(formData)
            });
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return [false, null]
            }

            return [true, dataResponse["table_id"]];
        } catch (e) {
            notification.error({
                message: `Error`,
                description: e.toString(),
                placement: "bottomLeft",
            });

        }

    }

    const putColumnDataRequest = async (columnId, newValues) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/column/${columnId}`;
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify(newValues)
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return [false, null]
        }

        return [true, dataResponse];
    }


    const getSelectedColumnTypeDataRequest = async (columnId, typeId) => {
        const jwtToken = getToken();
        const requestUrl = `${ENV_API_HOST}/column/${columnId}/type/${typeId}/`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwtToken}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return [false, null]
        }

        return dataResponse;
    }

    const getTableListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/table`;
        try {
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return []
            }

            return dataResponse["data"];
        } catch (e) {
            notification.error({
                message: `Error`,
                description: e.toString(),
                placement: "bottomLeft",
            });
            return []
        }
    }


    const getInterfaceListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/interface`;
        try {
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return []
            }

            return dataResponse["data"];
        } catch (e) {
            notification.error({
                message: `Error`,
                description: e.toString(),
                placement: "bottomLeft",
            });
            return []
        }
    }


    const getColumnTypeDetailsRequest = async (columnId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/column/type/${columnId}`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return []
        }

        return dataResponse;
    }

    const deleteRowListRequest = async (tableId, frontendRowIdList) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/table/${tableId}/rows`;
        const response = await fetch(requestUrl, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "rows": frontendRowIdList
            })
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return null;
        }

        return dataResponse;
    }


    const getAuthMeRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/me`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },

        });
        const dataResponse = await response.json();
        const status = response.status;
        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: dataResponse["detail"],
                placement: "bottomLeft",
            });
            return {status, dataResponse};
        }

        return {status, dataResponse};
    }

    const postCreateNewWorkspaceRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/workspace`;
        const response = await fetch(requestUrl, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return null;
        }

        return dataResponse;
    }

    const getWorkspaceRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/workspace`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return null;
        }

        return dataResponse;
    }

    const getPluginListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/plugin`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();

        if (!response.ok) {
            notification.error({
                message: `Error`,
                description: JSON.stringify(dataResponse),
                placement: "bottomLeft",
            });
            return [];
        }

        return dataResponse["plugin_list"];
    }

    const postInstallPluginRequest = async (pluginId) => {
        const token = getToken();
        try {
            const requestUrl = `${ENV_API_HOST}/plugin/${pluginId}/install`;
            const response = await fetch(requestUrl, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return [];
            }

            return dataResponse["plugin_list"];
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            return []
        }

    }

    const getDataTypeListRequest = async () => {
        const token = getToken();
        try {
            const requestUrl = `${ENV_API_HOST}/column/type`;
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return [];
            }
            return dataResponse["column_type_list"];
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            return []
        }
    }



    const getInstalledPluginListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/account/plugin`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        const response = await makeMetaRequest(requestUrl, params);
        if (response == null) {
            return {}
        }
        return response;
    }


    const getFieldSchemaSettingsRequest = async (accountPluginId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/account/plugin/${accountPluginId}/settings`;
        const params = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }

        const response = await makeMetaRequest(requestUrl, params);
        if (response == null) {
            return {}
        }
        return response;
    }


    const postFieldSchemaSettingsRequest = async (accountPluginId, formData) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/account/plugin/${accountPluginId}/settings`;

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData)
        };
        const response = await makeMetaRequest(requestUrl, params);
        if (response == null) {
            return {}
        }
        return response;
    }




    const postRowRegenerationRequest = async (tableId, frontendRowId) => {
        const token = getToken();

        const requestUrl = `${ENV_API_HOST}/trigger/run/row`;
        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "table_id": tableId,
                "f_id": frontendRowId
            })
        };

        const response = await makeMetaRequest(requestUrl, params);
        console.log("postRowRegenerationRequest: response = ", response);

        if (response == null) {
            return {"cell_updates": []}
        }
        return response;

    }


    const postCellRegenerationRequest = async (tableId, frontendRowId, columnId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/trigger/run/cell`;
        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "table_id": tableId,
                "f_id": frontendRowId,
                "column_id": columnId
            })
        }

        const response = await makeMetaRequest(requestUrl, params);
        console.log("postCellRegenerationRequest: response = ", response);

        if (response == null) {
            return {"cell_updates": []}
        }
        return response;
    }


    const makeMetaRequest = async (url, params) => {
        try {
            const response = await fetch(url, params);
            const dataResponse = await response.json();

            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return [];
            }
            return dataResponse;
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            return null;
        }
    }


    const getSelectedInterfaceRequest = async (accountInterfaceId) => {
        const token = getToken();
        try {
            const requestUrl = `${ENV_API_HOST}/interface/${accountInterfaceId}`;
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const getSelectedInterfacePublicRequest = async (accountInterfaceSlug) => {
        const token = getToken();
        try {
            const requestUrl = `${ENV_API_HOST}/interface/public/${accountInterfaceSlug}`;
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }



    const getDataListRequest = async (tableId, frontendRowId = null) => {
        const token = getToken();
        let requestUrl = `${ENV_API_HOST}/table/${tableId}/row`
        if (frontendRowId != null) {
            requestUrl = `${requestUrl}?frontend_row_id=${frontendRowId}`
        }

        try {
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const postWorkflowRequest = async (values) => {
        const token = getToken();
        let requestUrl = `${ENV_API_HOST}/workflow`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        };

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }

    const getWorkflowStatusByFrontendRowIdRequest = async (workflowExecutionId, frontendRowId) => {
        const token = getToken();
        // /workflow_execution/<workflow_execution_id>/f_id/<frontend_id>
        let requestUrl = `${ENV_API_HOST}/workflow_execution/${workflowExecutionId}/f_id/${frontendRowId}`

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        };

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse;
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const deleteWorkflowRequest = async (workflowId) => {
        const token = getToken();
        let requestUrl = `${ENV_API_HOST}/workflow/${workflowId}`

        const params = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        };

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const getWorkflowListRequest = async (tableId = null) => {
        const token = getToken();
        let requestUrl = `${ENV_API_HOST}/workflow`

        if (tableId != null) {
            requestUrl = `${requestUrl}?table=${tableId}`
        }

        try {
            const response = await fetch(requestUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }

    const putWorkflowNameRequest = async (workflowId, name) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/workflow/${workflowId}/name`

        const params = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "name": name,
            })
        }

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse['data'];
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const runWorkflowRequest = async (workflowId, frontendRowId, accountInterfaceId = null) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/workflow/${workflowId}/run`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "f_id": frontendRowId,
                "accountInterfaceId": accountInterfaceId,
            })
        }

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse;
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }

    const putTableMetaDataRequest = async (tableId, newName) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/table/${tableId}/`;
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "name": newName,
            })
        });
        const dataResponse = await response.json();
        return dataResponse;
    }


    const deleteTableRequest = async (tableId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/table/${tableId}/`;
        const response = await fetch(requestUrl, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        return dataResponse;
    }


    const putInterfaceMetaDataRequest = async (interfaceId, newName) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/`

        const params = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "name": newName,
            })
        }

        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse;
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }


    const deleteInterfaceRequest = async (interfaceId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/`

        const params = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }



        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let responseData = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, responseData};
            }
            responseData = dataResponse;
            return {isOk, responseData};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const responseData = null;
            return {isOk, responseData};
        }
    }



    const updateProfileRequest = async (profileObject) => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/profile/`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(profileObject)
        }

        return await metaRequest(requestUrl, params);
    }


    const createApiKeyRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/account/api-key/`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const getApiKeyListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/account/api-key/`

        const params = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }

    const putApiKeyRequest = async (apiKey, formValues) => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/account/api-key/${apiKey}`

        const params = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formValues)
        }

        return await metaRequest(requestUrl, params);
    }


    const deleteApiKeyRequest = async (apiKey) => {
        const token = getToken();
        const requestUrl = `${ENV_ACCOUNT_API_HOST}/account/api-key/${apiKey}`

        const params = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const metaRequest = async (requestUrl, params) => {
        try {
            const response = await fetch(requestUrl, params);
            const dataResponse = await response.json();
            let apiResponse = null;
            const isOk = response.ok;
            if (!response.ok) {
                notification.error({
                    message: `Error`,
                    description: JSON.stringify(dataResponse),
                    placement: "bottomLeft",
                });
                return {isOk, apiResponse};
            }
            apiResponse = dataResponse;
            return {isOk, apiResponse};
        } catch (error) {
            notification.error({
                message: `Error`,
                description: error.message || 'Cannot connect to the server',
                placement: "bottomLeft",
            });
            const isOk = false;
            const apiResponse = null;
            return {isOk, apiResponse};
        }
    }


    const getTableColumnListRequest = async (tableId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/table/${tableId}/column`;
        const response = await fetch(requestUrl, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        const dataResponse = await response.json();
        return dataResponse;
    }


    const postTemplateRequest = async (values) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        }
        return await metaRequest(requestUrl, params);
    }


    const getTemplateListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template`

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }

    const getAccountTemplateListRequest = async () => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/account`

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const postInstallTemplateRequest = async (values) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/install`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        }

        return await metaRequest(requestUrl, params);
    }


    const postTemplateNewVersionRequest = async (templateId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/${templateId}/version`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }

    const postTemplateMigrateVersionRequest = async (templateId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/${templateId}/migrate`

        const params = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const getInterfaceExecutionHistoryListRequest = async (interfaceId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/interface/${interfaceId}/history`

        const params = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const updateTemplateAccessLevel = async (templateId, values) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/${templateId}/access`

        const params = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values)
        }

        return await metaRequest(requestUrl, params);
    }


    const deleteTemplateRequest = async (templateId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/${templateId}/`

        const params = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    const deleteAccountTemplateRequest = async (accountTemplateId) => {
        const token = getToken();
        const requestUrl = `${ENV_API_HOST}/template/account/${accountTemplateId}/`

        const params = {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        }

        return await metaRequest(requestUrl, params);
    }


    return {
        getInstalledPluginListRequest,
        getFieldSchemaSettingsRequest,
        postFieldSchemaSettingsRequest,
        postRowRegenerationRequest,
        postCellRegenerationRequest,

        createTableRequest,
        createInterfaceRequest,
        getInterfaceListRequest,
        getSelectedInterfaceRequest,
        getSelectedInterfacePublicRequest,

        putInterfaceSourceTableRequest,
        putInterfaceVersionFieldsChangesRequest,
        putInterfaceVersionChangesSettingsRequest,
        postInterfaceVersionPublishRequest,
        postInterfaceVersionCreateNewRowRequest,

        getDataListRequest,
        postWorkflowRequest,
        getWorkflowListRequest,
        putWorkflowNameRequest,
        deleteWorkflowRequest,
        runWorkflowRequest,
        getWorkflowStatusByFrontendRowIdRequest,

        postColumnForTableRequest,
        putColumnDataRequest,
        getSelectedColumnTypeDataRequest,
        getColumnTypeDetailsRequest,
        getTableListRequest,
        deleteRowListRequest,
        getAuthMeRequest,
        postCreateNewWorkspaceRequest,
        getWorkspaceRequest,
        getPluginListRequest,
        postInstallPluginRequest,
        getDataTypeListRequest,

        putTableMetaDataRequest,
        deleteTableRequest,
        putInterfaceMetaDataRequest,
        deleteInterfaceRequest,

        updateProfileRequest,

        createApiKeyRequest,
        getApiKeyListRequest,
        putApiKeyRequest,
        deleteApiKeyRequest,

        getTableColumnListRequest,
        postTemplateRequest,
        getTemplateListRequest,
        getAccountTemplateListRequest,

        postInstallTemplateRequest,
        postTemplateNewVersionRequest,
        postTemplateMigrateVersionRequest,

        getInterfaceExecutionHistoryListRequest,
        updateTemplateAccessLevel,
        deleteTemplateRequest,
        deleteAccountTemplateRequest,

        metaRequest
    };
};




// getDataTypeListRequest



const putColumnOrderRequest = async (columnId, newOrder) => {
    const requestUrl = `${ENV_API_HOST}/column/${columnId}/order`;
    const response = await fetch(requestUrl, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({"order": newOrder})
    });
    const dataResponse = await response.json();
    return dataResponse;
}


const postRowForTableRequest = async (tableId, frontendRowId) => {
    const requestUrl = `${ENV_API_HOST}/table/${tableId}/row`;
    const response = await fetch(requestUrl, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({"f_id": frontendRowId})
    });
    const dataResponse = await response.json();
    console.log("getDataListRequest = ", dataResponse);
    return dataResponse;
}



//
// const postRowForTableRequest = async (tableId, frontendRowId) => {
//     const requestUrl = `${ENV_API_HOST}/table/${tableId}/row`;
//     const response = await fetch(requestUrl, {
//         method: "POST",
//         headers: {
//             'Content-Type': 'application/json',
//             // Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({"f_id": frontendRowId})
//     });
//     const dataResponse = await response.json();
//     console.log("getDataListRequest = ", dataResponse);
//     return dataResponse;
// }


const putCellValueTableRequest = async (tableId, frontendRowId, columnId, content) => {
    const requestUrl = `${ENV_API_HOST}/table/${tableId}/row/${frontendRowId}`;
    const response = await fetch(requestUrl, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            "column_id": columnId,
            "value": content
        })
    });
    const dataResponse = await response.json();
    console.log("putCellValueTableRequest = ", dataResponse);
    return dataResponse;
}






const getContextColumnListRequest = async (tableId) => {
    const requestUrl = `${ENV_API_HOST}/context/column/based_on_table/${tableId}/`;
    const response = await fetch(requestUrl, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const dataResponse = await response.json();
    return dataResponse;
}









const getCellUpdatesForSelectedTableRequest = async (tableId, datetime, frontendRowId = null) => {
    let requestUrl = `${ENV_API_HOST}/table/${tableId}/updates?time=${encodeURIComponent(datetime)}`;
    if(frontendRowId != null) {
        requestUrl = `${requestUrl}&f_id=${frontendRowId}`
    }

    const response = await fetch(requestUrl, {
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const dataResponse = await response.json();
    return dataResponse;
}

//
// const postCellRegenerationRequest = async (tableId, frontendRowId, columnId) => {
//     const requestUrl = `${ENV_API_HOST}/trigger/run/cell`;
//     const response = await fetch(requestUrl, {
//         method: "POST",
//         headers: {
//             'Content-Type': 'application/json',
//             // Authorization: `Bearer ${token}`,
//         },
//         body: JSON.stringify({
//             "table_id": tableId,
//             "f_id": frontendRowId,
//             "column_id": columnId
//         })
//     });
//     const dataResponse = await response.json();
//     return dataResponse;
// }






const putColumnWidthSizeRequest = async (columnId, width) => {
    const requestUrl = `${ENV_API_HOST}/column/${columnId}/width`;
    const response = await fetch(requestUrl, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            "width": width,
        })
    });
    const dataResponse = await response.json();
    return dataResponse;
}



export {
    useApi,
    putColumnOrderRequest,
    postRowForTableRequest,
    putCellValueTableRequest,
    getContextColumnListRequest,
    // getInstalledPluginListRequest,
    // getFieldSchemaSettingsRequest,
    // postFieldSchemaSettingsRequest,
    getCellUpdatesForSelectedTableRequest,
    // postRowRegenerationRequest,
    // postCellRegenerationRequest,
    // getTableColumnListRequest,
    // putTableMetaDataRequest,
    // deleteTableRequest,
    putColumnWidthSizeRequest
}
