import {
    Badge,
    Button,
    Col,
    Form,
    Input,
    Modal, Popconfirm, Radio,
    Row,
    Select,
    Skeleton,
    Switch,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {useEffect, useState} from "react";
import {useApi} from "../utils/requests";
import {Link} from "react-router-dom";
import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faLink, faLinkSlash, faLock, faPenToSquare, faUnlock} from "@fortawesome/free-solid-svg-icons";

const { Text } = Typography;


function InstalledTemplateStatusCell(props) {
    const {status} = props;

    let statusRender = null;

    if(status == "IDLE") {
        statusRender = <Tag color={"green"}>Ready to use</Tag>
    }

    if (status == "IN_MIGRATION") {
        statusRender = <Tag color={"blue"}>Migration in the process ...</Tag>
    }

    return(<span>{statusRender}</span>)
}

function InstalledTemplateActionCell(props) {
    const {record} = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const {deleteAccountTemplateRequest} = useApi();

    const handleDeleteRequest = async () => {
        setIsDeleting(true);
        await deleteAccountTemplateRequest(record.id);
        props.onUpdate();
        setIsDeleting(false);
    }


    return(<span>
        <Popconfirm
            title="Delete the template"
            description={<span>If you delete this installed template, you won't be able to restore it in the future.</span>}
            onConfirm={handleDeleteRequest}
            placement="left"
            okText="Delete"
            okButtonProps={{
                loading: isDeleting,
            }}
            cancelText="Cancel"
        >
            <Button>Delete</Button>
        </Popconfirm>
    </span>)
}

function InstalledTemplateList(props) {
    const {updateToken} = props;
    const {getAccountTemplateListRequest} = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [templateList, setTemplateList] = useState([]);

    const columns = [
        {
            title: 'Template',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span>{record.name}</span>,
        },
        {
            title: 'Related entities',
            dataIndex: 'used_entities',
            key: 'used_entities',
            render: (text, record) => <span><RelatedEntitiesCell record={record["entities"]} /></span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <span><InstalledTemplateStatusCell status={record.status} /></span>,
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
            render: (text, record) => <span> <MigrationCell record={record.migration} templateId={record.id} /></span>,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <span><InstalledTemplateActionCell record={record} onUpdate={handleCellUpdate} /></span>,
        },
    ];



    useEffect(() => {
        loadingInstalledDataRequest();
    }, []);

    useEffect(() => {
        loadingInstalledDataRequest();
    }, [updateToken]);

    const handleCellUpdate = () => {
        loadingInstalledDataRequest();
    }

    const loadingInstalledDataRequest = async () => {
        setIsLoading(true);
        const {isOk, apiResponse} = await getAccountTemplateListRequest();
        if (isOk) {
            const templateList = apiResponse["account_template_list"];
            console.log("loadingInstalledDataRequest -> templateList: ", templateList);
            setTemplateList(templateList);
        }
        setIsLoading(false);
    }

    if (isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }

    return(<Table dataSource={templateList} columns={columns}/>);

}


function MigrationCell(props) {
    const {record, templateId} = props;
    const {postTemplateMigrateVersionRequest} = useApi();
    const [isLoading, setIsLoading] = useState(false);

    const onInstallButton = async () => {
        setIsLoading(true);
        const {isOk, apiResponse} = await postTemplateMigrateVersionRequest(templateId);
        setIsLoading(false);
    }

    let renderMigrationState = null;
    if(record["is_deattached_copy"]) {
        renderMigrationState = (<span>
            <Tooltip placement="top" title={"You can't get new versions for detached copy. If you want to install a new version - install the template again."}>
                <Text type="secondary"><FontAwesomeIcon icon={faLinkSlash} /> Detached copy</Text>
            </Tooltip>
        </span>);
    } else {
        // console.log("renderMigrationState = record = ", record);
        let migrationStatus = <Tag color="green">the latest</Tag>;
        if (record["next_version_id"] != record["id"]) {
            renderMigrationState = <span><Tag color="blue">Available a new version</Tag><Button type={"primary"} size={"small"} loading={isLoading} onClick={onInstallButton}>Install</Button></span>;
        }

        renderMigrationState = <span>{record["created_at"]} {renderMigrationState}</span>
    }

    return(<span>{renderMigrationState}</span>);
}


function TemplateOwnVersion(props) {
    const {record} = props;
    return(<span><Badge count={record["published_version"]["version_count"]} showZero color="#BFBFBF" /> <span>{record["published_version"]["created_at"]}</span></span>)
}


function OwnTemplateList(props) {
    const {getTemplateListRequest} = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [templateList, setTemplateList] = useState([]);


    const columns = [
        {
            title: 'Template',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span>{record.name}</span>,
        },
        {
            title: 'Related entities',
            dataIndex: 'used_entities',
            key: 'used_entities',
            render: (text, record) => <span><RelatedEntitiesCell record={record} /></span>,
        },
        {
            title: 'Settings',
            dataIndex: 'settings',
            key: 'settings',
            // width: 400,
            render: (text, record) => <span><TemplateSettingsCell record={record} /></span>,
        },
        {
            title: 'Access',
            dataIndex: 'access_level',
            key: 'access_level',
            render: (text, record) => <span><TemplateAccessLevelCell record={record} onUpdate={onUpdateData} /></span>,
        },
        {
            title: 'Published version',
            dataIndex: 'version',
            key: 'version',
            render: (text, record) => <span><TemplateOwnVersion record={record} /></span>,
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: 350,
            render: (text, record) => <span><OwnTemplateActions record={record} onUpdate={onUpdateData} /></span>,
        },
    ];


    const loadingOwnDataRequest = async () => {
        setIsLoading(true);
        const {isOk, apiResponse} = await getTemplateListRequest();
        const templateList = apiResponse["template_list"];
        setTemplateList(templateList);
        setIsLoading(false);
    }


    useEffect(() => {
        loadingOwnDataRequest();
    }, []);


    const onUpdateData = () => {
        loadingOwnDataRequest();
    }

    if (isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }


    return (<Table dataSource={templateList} columns={columns}/>);

}


function CreateTemplateButton(props) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {getTableListRequest, postTemplateRequest} = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [tableLabelList, setTableLabelList] = useState([]);

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleOpen = () => {
        setIsModalOpen(true);
    }

    useEffect(() => {
        console.log("TemplateView = useEffect")
        loadingData();
    }, []);

    const loadingData = async () => {
        setIsLoading(true);
        const tableList = await getTableListRequest();
        console.log("tableList = ", tableList);
        let renderList = [];
        for (let i = 0; i < tableList.length; i++) {
            renderList.push({ value: tableList[i].id, label: tableList[i].name })
        }
        setTableLabelList(renderList);
        setIsLoading(false);
    }

    const createTemplateRequest = async (values) => {
        setIsCreateLoading(true);
        const {isOk, apiResponse} = await postTemplateRequest(values);
        if (isOk) {
            props.onUpdate();
        }

        setIsCreateLoading(false);
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("[handleOk] value = ", values);
                createTemplateRequest(values);
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }

    return(<span>
        <Button onClick={handleOpen} style={{marginTop: "12px"}}>New Template</Button>
        <Modal
            title="Create a new template"
            okButtonProps={{
                // disabled: isTableDataLoading || isColumnDataLoading,
                loading: isCreateLoading
            }}
            okText={"Create"}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name={"name"} label={"Name:"}>
                    <Input/>
                </Form.Item>

                <Form.Item name={"is_deattached_copy"} label={"Is it a detached copy?"} valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item name={"table_list"} label={"Tables:"}>
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        loading={isLoading}
                        options={tableLabelList}
                    />
                </Form.Item>

            </Form>
        </Modal>
    </span>)
}


function InstallTemplateButton(props) {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {postInstallTemplateRequest} = useApi();
    const [isLoading, setIsLoading] = useState(false);


    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleOpen = () => {
        setIsModalOpen(true);
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("[handleOk] value = ", values);
                handleRequest(values);
                props.onUpdate(Math.random());
                setIsModalOpen(false);
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }
    
    const handleRequest = async (values) => {
        setIsLoading(true);
        const {isOk, apiResponse} = await postInstallTemplateRequest(values);
        setIsLoading(false);
    }

    return(<span>
        <Button onClick={handleOpen} style={{marginTop: "12px"}}>Install Template</Button>
        <Modal
            title="Template installation"
            okButtonProps={{
                // loading: isCreateLoading
            }}
            okText={"Install"}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name={"template_slug"} label={"Template public id:"}>
                    <Input/>
                </Form.Item>

            </Form>
        </Modal>
    </span>)
}


function RelatedEntitiesCell(props) {
    const {record} = props;

    console.log("RelatedEntitiesCell = ", record);

    if (record == null || record == undefined) {
        return (<span>No data</span>);
    }
    let cellValue = "No selected tables";

    const tableList = record["used_table_list"];
    console.log("tableList = ", tableList);
    let renderTableList = null;
    let renderTable = null;
    if (tableList && tableList.length > 0) {
        let renderTableList = tableList.map((table, index) => (
            <React.Fragment key={table.id}>
                <Link to={`/table/${table.id}`}>{table.name}</Link>
                {index < tableList.length - 1 ? ", " : ""}
            </React.Fragment>
        ));

        renderTable = <span>Tables: {renderTableList}</span>
    }

    const interfaceList = record["used_interface_list"];
    if (interfaceList && interfaceList.length > 0) {
        let interfaceRender = null;
        if (interfaceList && interfaceList.length > 0) {
            const renderInterfaceList = interfaceList.map((interfaceEntity, index) => (
                <React.Fragment key={interfaceEntity.id}>
                    <Link to={`/interface/${interfaceEntity.id}/`}>{interfaceEntity.name}</Link>
                    {index < interfaceList.length - 1 ? ", " : ""}
                </React.Fragment>
            ));
            console.log("tableList (from interface iteration) = ", tableList);
            if (tableList != undefined && tableList.length > 0) {
                interfaceRender = <span>. Interfaces: {renderInterfaceList}</span>;
            } else {
                interfaceRender = <span>Interfaces: {renderInterfaceList}</span>;
            }
        }



        cellValue = <span>{renderTable}{interfaceRender}</span>;
    }


    return(<span>
        {cellValue}
    </span>)
}


function TemplateAccessLevelCell(props) {
    const {record} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSavingChanges, setIsSavingChanges] = useState(false);
    const [form] = Form.useForm();
    const {updateTemplateAccessLevel} = useApi();

    const accessLevel = record["access_level"];
    console.log(" accessLevel = ", accessLevel);

    useEffect(() => {
        form.setFieldValue("access_level", record["access_level"]);
    }, []);

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const handleOpen = () => {
        setIsModalOpen(true);
    }


    let accessLevelRender = "—";
    if (accessLevel == "PRIVATE") {
        accessLevelRender = <a onClick={handleOpen} style={{color: "#E52828"}}><FontAwesomeIcon icon={faLock} /> Private</a>;
    }

    if (accessLevel == "PUBLIC_LINK") {
        accessLevelRender = <a onClick={handleOpen} style={{color: "#51C393"}}><FontAwesomeIcon icon={faUnlock} /> Public</a>;
    }


    const updateAccessLevel = async (values) => {
        setIsSavingChanges(true);
        await updateTemplateAccessLevel(record["id"], values);
        props.onUpdate();
        setIsSavingChanges(false);
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                console.log("value = ", values);
                updateAccessLevel(values)
            })
            .catch((errorInfo) => {
                console.log("errorInfo = ", errorInfo);
            });
    }

    return(<span>
        {accessLevelRender}

        <Modal
            title="Access management"
            okButtonProps={{
                loading: isSavingChanges
            }}
            okText={"Save changes"}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}>
            <div>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item name={`access_level`} label={"Access level:"}>
                        <Radio.Group>
                            <Radio value="PUBLIC_LINK">Public access</Radio>
                            <Radio value="PRIVATE">Private</Radio>
                          </Radio.Group>
                    </Form.Item>
                </Form>
                Template public id: <br />
                <Input value={record["slug"]} />

            </div>

        </Modal>

    </span>)
}


function OwnTemplateActions(props) {
    const {record} = props;
    const {postTemplateNewVersionRequest, deleteTemplateRequest} = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const createNewVersion = async () => {
        setIsLoading(true);
        const {isOk, apiResponse} = await postTemplateNewVersionRequest(record["id"]);
        props.onUpdate();
        setIsLoading(false);
    }


    const onConfirm = () => {
        createNewVersion();
    }


    const deleteRequest = async () => {
        setIsDeleting(true);
        await deleteTemplateRequest(record["id"]);
        props.onUpdate();
        setIsDeleting(false);
    }

    const onConfirmDelete = () => {
        deleteRequest();
    }

    return(<span>
         <Popconfirm
             title="Create & publish a new version"
             description={<span>Create a new version of your template to give users a new experience. <br/> They must update to the new version manually. <br/> You can't roll back to the previous version, so please test it first. </span>}
             onConfirm={onConfirm}
             okText="Create & publish a version"
             okButtonProps={{
                 loading: isLoading,
             }}
             cancelText="Cancel"
         >
             <Button>Publish a new version</Button>
         </Popconfirm>&nbsp;
        {/*<Button>Edit</Button>*/}
        {/*&nbsp;*/}

        <Popconfirm
            title="Delete the template"
            description={<span>If you delete the template, you won't be able to restore it in the future.</span>}
            onConfirm={onConfirmDelete}
            placement="left"
            okText="Delete"
            okButtonProps={{
                loading: isDeleting,
            }}
            cancelText="Cancel"
        >
            <Button>Delete</Button>
        </Popconfirm>
    </span>)
}

function TemplateSettingsCell(props) {
    const {record} = props;


    let tableAccess =  <span> / <FontAwesomeIcon icon={faEye}/> Editing is restricted</span>;
    if(record["is_table_access_enabled"]) {
        tableAccess = <span> / <FontAwesomeIcon icon={faPenToSquare}/> Editing is allowed</span>
    }

    let deattachedCopy = <span><FontAwesomeIcon icon={faLink} /> Attached copy {tableAccess}</span>;
    if (record["is_deattached_copy"]) {
        deattachedCopy = <span><FontAwesomeIcon icon={faLinkSlash} /> Detached copy</span>;
    }

    return(<span>{deattachedCopy}</span>)
}


function TemplateActionsCell(props) {
    return(<span>qwe</span>)
}


function TemplateView(props) {
    const [selectedTab, setSelectedTab] = useState("USED");

    const [installTemplateToken , setInstallTemplateToken] = useState(null);

    const items = [
        {
            key: 'USED',
            label: 'Installed templates',
        },
        {
            key: 'OWN',
            label: 'Your own templates',
        },
    ];



    const handleCreationUpdate = () => {

    }

    const onChangeTab = (tabId) => {
        setSelectedTab(tabId);
    }

    const handleInstallUpdate = (updToken) => {
        console.log("handleInstallUpdate token = ", updToken);
        setInstallTemplateToken(updToken);
    }

    return (<div style={{background: "#fff", paddingLeft: "20px", paddingRight: "20px"}}>
        <Row>
            <Col span={20}>
                <h2>Templates</h2>
            </Col>
            <Col span={4}>
                {selectedTab == "OWN" ? <CreateTemplateButton onUpdate={handleCreationUpdate} />: null }
                {selectedTab == "USED" ?  <InstallTemplateButton onUpdate={handleInstallUpdate} /> : null}
            </Col>
        </Row>
        <Row>
            <Tabs defaultActiveKey={selectedTab} items={items} onChange={onChangeTab} />
        </Row>
        <Row>
            <Col span={24}>
                {/*{isLoading ? <div style={{padding: "10px 10px 0px 10px", height: "100vh"}}>*/}
                {/*    <Skeleton active/>*/}
                {/*    <Skeleton active/>*/}
                {/*    <Skeleton active/>*/}
                {/*</div>: null}*/}

                {selectedTab == "OWN" ?  <OwnTemplateList />  : null}
                {selectedTab == "USED" ?  <InstalledTemplateList updateToken={installTemplateToken} /> : null}
            </Col>
        </Row>
    </div>)

}

export default TemplateView;
