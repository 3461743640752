import InterfaceView from "./InterfaceView";
import {TipTapRender} from "@troop.com/tiptap-react-render";
import {useApi} from "../utils/requests";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Empty, Skeleton} from "antd";
import {marked} from 'marked';
import "../utils/style.css";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FileDownloadFormItem} from "../components/InterfaceForm";


function InterfaceHistoryView(props) {
    let {interfaceId} = useParams();
    const {getInterfaceExecutionHistoryListRequest} = useApi();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [renderData, setRenderData] = useState([]);


    const getMarkdownText = (text) => {
        const rawMarkup = marked(text, { sanitize: true });
        return { __html: rawMarkup };
    };

    const getData = async () => {
        setIsLoading(true);
        const {isOk, apiResponse} = await getInterfaceExecutionHistoryListRequest(interfaceId);
        if (isOk) {
            const responseDataList = apiResponse["data"];
            let renderDataList = [];
            setData(responseDataList);

            for (let i = 0; i < responseDataList.length; i++) {
                const fieldDataList = responseDataList[i]["data"];

                let renderCardFormList = [];
                for (let j = 0; j < fieldDataList.length; j++) {
                    let cardContent = null;

                    if(fieldDataList[j]["field_frontend_type"] == "DOWNLOAD_LINK") {
                        cardContent = <p>
                            <div>
                                <FileDownloadFormItem fileUrl={fieldDataList[j]["field_data"] == null ? null : fieldDataList[j]["field_data"]} disabled={false} />
                            </div>
                        </p>
                    } else if (fieldDataList[j]["field_frontend_type"] == "FILE") {
                        const metadata = JSON.parse(fieldDataList[j]["field_meta_data"]["d"]);
                        const downloadLink = metadata["public_path"];
                        cardContent = <p>
                            <div>
                                <FileDownloadFormItem fileUrl={downloadLink == null ? null : downloadLink} disabled={false} />
                            </div>
                        </p>;
                    } else {
                        cardContent = <p>
                            <div
                                style={{whiteSpace: 'pre-wrap'}}
                                className={"ellipsis"}
                                // dangerouslySetInnerHTML={getMarkdownText()}
                            >{fieldDataList[j]["field_data"]}</div>
                        </p>
                    }


                    let cellHeader = null
                    if(fieldDataList[j]["field_name"] != null) {
                        cellHeader = <h3>{fieldDataList[j]["field_name"]}:</h3>
                    }

                    renderCardFormList.push(
                        <div>
                            {cellHeader}
                            {cardContent}
                    </div>)
                }

                renderDataList.push(<Card style={{marginBottom: "15px"}} title={<span>Interface was executed at: {responseDataList[i]["created_at"]}</span>} bordered={false} >
                    {renderCardFormList}
                </Card>);
            }

            setRenderData(renderDataList);


        }
        setIsLoading(false);
    }

    useEffect(() => {
        getData();
    }, []);


    if(isLoading) {
        return (<div style={{padding: "10px 10px 0px 10px"}}>
            <Skeleton active/>
            <Skeleton active/>
            <Skeleton active/>
        </div>);
    }

    if(data.length == 0) {
        return (<div style={{padding: "10px 10px 0px 10px"}}>
            <Empty />
        </div>);
    }

    return(<div>
        <div style={{backgroundColor: "#fff", padding: "5px 10px 5px 10px"}}>
            <Button type="link" onClick={() => {
                navigate(`/interface/${interfaceId}/`);
            }}>
                <FontAwesomeIcon icon={faChevronLeft} /> &nbsp; Back to the interface
            </Button>
        </div>
        <div style={{padding: "10px 20px"}}>
        {renderData}
    </div>
    </div>)
}

export default InterfaceHistoryView;
